import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Fab from "@mui/material/Fab";
import { Typography } from "@mui/material";
import Divider from '@mui/material/Divider';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import { useState } from "react";
import { Cookie, DisabledByDefault } from "@mui/icons-material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const ProtectedHelp = () => {
  /**
   * This method is used to download the PDF document
   */

  const [value, setValue] = useState("overview")
  const [subTabValue, setSubTabValue] = useState("overview")

  const handleDownload = () => {
    const filePath = "/assets/OLM For SFTP.pdf";

    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "OLM For SFTP.pdf");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  function handleChange(eve, val) {
    setValue(val)
  }

  function subMenuSelect(event, val) {
    setSubTabValue(val)

  }

  return (
    <>
      <div className="helpSectionContainer">
        <div className="helpSectionContent">
          <Typography variant="h5" my={1}>
            User Manual
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="secondary tabs example"
          >
            <Tab value="overview" label="Overview" />
            <Tab value="job" label="Job Management" />

            <Tab value="settings" label="Settings" />
            <Tab value="serverManagement" label="Server Management" />
          </Tabs>
          {value == 'overview' && <Box my={2}>
            <Typography variant="body2">
              <div style={{ textAlign: 'justify' }}>
                {/* <Typography variant="h5">Outbound List Manager for SFTP</Typography> */}
                <p><Typography variant="body2">The Outbound List Manager for SFTP is a robust tool designed to streamline the automation of contact list and DNC list management for companies. This tool facilitates the import of both contact lists and DNC lists from data files located on a configured SFTP server, which can be scheduled to run at regular intervals or triggered on-demand as needed.</Typography></p>
                <p><Typography variant="body2">Additionally, the Outbound List Manager supports the automated export of contact lists and DNC lists from the GC system to CSV files. These files are then uploaded to a configured SFTP server, ensuring seamless data transfer and integration.</Typography></p>
                <Typography variant="h6">Key Features:</Typography>
                <ul>
                  <li><strong>Automated Import and Export:</strong>
                    <ul>
                      <li><strong>Import:</strong> Automate the import of contact lists and DNC lists from data files on a configured SFTP server. This can be scheduled or executed on-demand.</li>
                      <li><strong>Export:</strong> Automate the export of contact lists and DNC lists from the GC system to CSV files, which are then uploaded to a configured SFTP server.</li>
                    </ul>
                  </li>
                  <li><strong>File Encryption:</strong>
                    <ul>
                      <li><strong>Support for Encrypted and Non-Encrypted Files:</strong> Customers can choose to import and export both encrypted and non-encrypted files.</li>
                      <li><strong>PGP Encryption:</strong> If encryption is preferred, customers must provide one or more public keys for PGP encryption to ensure data security during transfer.</li>
                    </ul>
                  </li>
                  <li><strong>Transfer Options:</strong>
                    <ul>
                      <li><strong>Post-Transfer Actions:</strong> Customers can configure the system to either delete or move the imported files after the successful import of contacts and DNC lists, providing flexibility in file management.</li>
                    </ul>
                  </li>
                </ul>
                <p><Typography variant="body2">This comprehensive functionality ensures that companies can efficiently manage their contact lists and DNC lists with minimal manual intervention, enhancing productivity and data security.</Typography></p>
              </div>


              {/* <p>The Outbound List Manager for SFTP enables companies to automate import of contact lists from data files from a configured SFTP server on either a scheduled or on-demand basis.  </p>
              <p>It also allows the customer to automate exports of contact lists from GC to csv files that are uploaded to a configured sftp server.</p>
              <p>Customers can import, and export encrypted and non-encrypted files. If encryption option is preferred, one or more public keys must be provided for PGP encryption. </p>
              <p>Customers can also set transfer options to either delete or move the imported files after successful import of contacts.</p> */}

            </Typography>
          </Box>
          }
          {value == 'job' && <Box my={1}>
            <div>
              <Typography variant="body2">

                The application provides the following features:<ul>
                  <li>Scheduling Importing and export jobs</li>
                  <li>Settings</li>
                  <li>Server management</li>
                </ul>
              </Typography>
            </div>

            <Accordion>
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <b>Status Dashboard</b>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <div style={{ textAlign: 'justify' }}>
                    <p>The initial view in the app is the Jobs View which is a list of jobs previously created.  This can also be viewed by clicking on the Jobs View icon on the left-hand side, side bar. The list will provide information on the name of the job, description, user who created the job, job schedule as well as job execution details. </p>
                    <p>Jobs can be either scheduled or non-schedule. A scheduled job is one that runs on a predefined ‘one time’ or ‘recurring schedule’. The schedule for a job is defined by a cron string. A non-scheduled job is one that does not have a defined schedule or cron string.</p>
                    <img src="../assets/help/jobmain.png" />
                    <br />
                    <p>
                      In addition to viewing details the user is also provided the following actions:
                      <ul style={{ marginTop: "5px" }}>
                        <li className="li-sp"><b>Delete job - </b> A single job can be deleted by clicking on the delete icon at the end of each row.</li>
                        <li className="li-sp"><b>Delete all jobs - </b> Selecting the check box on the top of the data table, allows the user to select all jobs. Clicking on the DELETE icon on the right-hand side top corner allows the user to delete all jobs.</li>
                        <li className="li-sp"><b>Edit - </b>Clicking on the pencil/edit icon opens the job in edit mode (see ‘new job’ for details). The user can make changes and click on the SUBMIT button to save changes.</li>
                        <li className="li-sp"><b>Enable/Disable - </b>This is a toggle option that allows the user to disable and enable a scheduled job. Once disable the job will not run on the configured schedule, until it is enabled again.</li>
                        <li className="li-sp"><b>Run job - </b>When this icon is clicked, the job is run immediately irrespective of the schedule.</li>
                        <li className="li-sp"><b>Job logs - </b>Clicking on this icon, directs the user to a job log screen. This screen shows the user details of the job’s last 15 runs along with the errors that may have occurred while running the job. This helps the user troubleshoot any issues.</li>

                      </ul>
                    </p>
                  </div>
                </Typography>

              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <b> Creating New Job </b>
              </AccordionSummary>
              <AccordionDetails>
                <Tabs
                  value={subTabValue}
                  onChange={subMenuSelect}
                  aria-label="secondary tabs example"
                >
                  <Tab value="overview" label="Overview" />
                  <Tab value="schedule" label="Schedule" />

                  <Tab value="fileTransfer" label="File Transfer" />
                  <Tab value="contactList" label="Contact List" />
                  <Tab value="dncList" label="DNC List" />
                </Tabs>

                {
                  subTabValue == 'overview' && <Box my={2}>
                    <div>
                      <Typography variant="body2">
                        <p> Within this view the user has the option to either edit a pre-defined job or create a new job by clicking on the NEW button.
                        </p>
                        <p>Enter all mandatory fields to configure a job based on your requirements. Click on the SAVE JOB button to save the job to the job list. The new job screen provides the following options:</p>
                      </Typography>
                    </div>
                  </Box>
                }
                {
                  subTabValue == 'schedule' && <Box my={2}>
                    <div>
                      <Typography variant="body2">

                        <p>The Schedule section allows the user to create either a schedule or a non-schedule job by toggling the scheduled button. If enabled, the user will be allowed to create a schedule by first selecting the time zone and then selecting a date and time on which the job should run. </p>

                        <p> <b> Creating One Time Schedule</b> </p>
                        <p>The schedule below would run the job one time on the 5th of April at 2:00 PM Los Angeles time (Pacific Time)</p>
                        <img src="../assets/help/onetimeschedule.png" />
                        <hr />
                        <p style={{ paddingTop: "10px" }}> <b> Recursive Schedule - Weekly Once </b></p>
                        <p>The user can also create a recursive schedule by clicking on the Recurrence button and then defining a recurrence with the parameters: minutes, daily, weeks, or months. For e.g., for a schedule of every Monday would set the recurrence as shown below:</p>
                        <img src="../assets/help/recursiveschedule.png" width={"500px"} />
                        <p style={{ paddingTop: "10px" }}> <b> Recursive Schedule - Particular Date in Month</b></p>
                        <p> To run the job only the particular date of the month. Select Repeat Month and select from the list of Days</p>
                        <img src="../assets/help/recursiveschedulemonth.png" width={"500px"} />


                      </Typography>

                    </div>
                  </Box>
                }


                {
                  subTabValue == 'fileTransfer' && <Box my={2}>
                    <div>
                      <Typography variant="body2">
                        <p>The transfer options view allows you to select a sftp server and enter the credentials for the job to use when accessing files on that server. The options for connection are:  </p>
                        <ul>
                          <li><b>Username and Password-</b> Authenticate the server using the user name and password to establish the connection </li>
                          <li><b>Ssh key - </b>SSH key is a key pair generated by the Outbound List Manager for SFTP application. You can click on the Download button key and then upload it to your SFTP server for use.</li>
                          <li><b>Ssh Key and Password-</b> Same as the above except a password can also be used, in addition to the ssh key, </li>
                        </ul>
                        <img src="../assets/help/transferOption.png" width={"500px"} />
                        <p>The app allows the user to test the SFTP connection with all 3 options, by clicking the TEST CONNECTION button. The user needs to specify the folder in which the import files will reside in the Directory field.</p>

                        <p>In addition to connection options, the user can select what needs to be done with the files, after contacts are successfully imported from the files to Genesys Cloud. Users can choose to delete the files or to move them to a specified folder on the SFTP server.</p>
                        <img src="../assets/help/posttransferOption.png" width={"500px"} />

                      </Typography>
                    </div>
                  </Box>
                }

                {
                  subTabValue == 'fileTransfer' && <Box my={2}>
                    <div>
                      <Typography variant="body2">
                        <p>The transfer options view allows you to select a sftp server and enter the credentials for the job to use when accessing files on that server. The options for connection are:  </p>
                        <ul>
                          <li><b>Username and Password-</b> Authenticate the server using the user name and password to establish the connection </li>
                          <li><b>Ssh key - </b>SSH key is a key pair generated by the Outbound List Manager for SFTP application. You can click on the Download button key and then upload it to your SFTP server for use.</li>
                          <li><b>Ssh Key and Password-</b> Same as the above except a password can also be used, in addition to the ssh key, </li>
                        </ul>
                        <img src="../assets/help/transferOption.png" width={"500px"} />
                        <p>The app allows the user to test the SFTP connection with all 3 options, by clicking the TEST CONNECTION button. The user needs to specify the folder in which the import files will reside in the Directory field.</p>

                        <p>In addition to connection options, the user can select what needs to be done with the files, after contacts are successfully imported from the files to Genesys Cloud. Users can choose to delete the files or to move them to a specified folder on the SFTP server.</p>

                      </Typography>
                    </div>
                  </Box>
                }

                {
                  subTabValue == 'contactList' && <Box my={2}>
                    <div>
                      <Typography variant="h6"> Import Contact List Configuration </Typography>
                      <Typography variant="body2" my={2}>
                        <p>A job can be of one of two types import or export. To import files from a SFTP server, select Import File, then specify a way of identifying data files in the Directory folder, by selecting one of the 6 file format operation options and then entering a file name format.  </p>
                      </Typography>
                      <img src="../assets/help/importContactList.png" width={"500px"} />
                      <p>For example, if the user selects “Starts With” in the File Format Operation and enters “March” as the file format name, then all files beginning with the word “March” will be selected for import from the folder specified under Directory (see transfer options above)</p>
                      <img src="../assets/help/importMatchCondition.png" />
                      <p>If the import files have a .gz or .zip extension, then the files will first be unzipped before they are imported. If the files are encrypted, then they will be decrypted before they are imported (if encryption setting is on)</p>
                      <p>To specify the fields in your contact list you have 2 options.</p>

                      <p><b>1.Upload sample file and map:</b></p>
                      <p>With this option you can use the interface shown below to select a sample csv or xlsx file. The file should be formatted in a way so that it has headers and at least one row of data. Once the data is loaded, you will be able to select the column that will be your contact phone column (mandatory), the type of the column (mandatory) and the column that needs to be used for time zone mapping (optional)</p>
                      <img src="../assets/help/uploadSampleMap.png" width={"500px"} />
                      <p>To find out more about contact list creation in Genesys Cloud click here: <a href="https://help.mypurecloud.com/articles/create-new-contact-list/">https://help.mypurecloud.com/articles/create-new-contact-list/</a></p>

                      <p><b>2)	Use a file import template:</b></p>
                      <p>You can use a file import template for list creation, by selecting from a list of pre-existing import templates. The drop down will show you templates that were created in all division that the user has access to.</p>
                      <img src="../assets/help/importTemplate.png" width={"500px"} />
                      <p>To learn more about import templates, click on the link below: <a href="https://help.mypurecloud.com/articles/create-an-import-template/"> https://help.mypurecloud.com/articles/create-an-import-template/ </a></p>

                      <p>File specification templates allow you to use data files that are delimited by other characters such as pipes, ampersands etc. On more information on these templates please click here:
                        https://help.mypurecloud.com/articles/create-a-file-specification-template
                      </p>
                      <p>In addition to the above options, you can also choose to create new contact lists (one for each import file) or append contents of all import files to an existing list. To create new lists, select “New Lists only” from the Append Type drop down. Make sure to select a division in which the new contact list needs to be created.</p>
                      <p>To append lists to an existing contact list, select “Append Only” and select the contact list you would like to append to, from the contact list drop down. This list will only show you contact lists from division that the user has access to.</p>
                      <p>To clear and append to an existing list, select the “Flush and Append” append type.</p>
                      <img src="../assets/help/flushAndAppend.png" width={"500px"} />

                      <p>If you wish to you use files other that comma separated csv or xls files, then you can create “file specification template” in GC. Then within your job configuration, you can select a preexisting “file specification template”.</p>
                      <img src="../assets/help/fileSpec.png" />
                      <Typography variant="h6"> Export Contact List Configuration </Typography>
                      <Typography variant="body2" my={2}>
                        <p>The user can create a job that exports contact lists from Genesys Clouds into csv files. The user can select one list per job using the contact lists dropdown. The exported files are then transferred to a folder on the SFTP server. The SFTP server config details, as well as the folder to which the files need to be exported to, can be entered in the File Transfer Options section (see section above)</p>
                        <img src="../assets/help/contactListOption.png" width={"500px"} />
                        <p>Checking the Zip Files option box, results in the exported files being compressed into zip files.</p>
                        <p>Checking the Encrypt Files option results in the exported files being encrypted with all the public keys that are uploaded by users within the Encryption Settings view. If this box is unchecked the job will not encrypt the exported file.</p>
                        <p>If both options are checked the exported file will be first compressed and then encrypted.</p>
                        <img src="../assets/help/compressAndEncrypt.png" width={"500px"} />
                      </Typography>
                    </div>
                  </Box>
                }


                {
                  subTabValue == 'dncList' && <Box my={2}>
                    <div>
                      <Typography variant="h6"> Import DNC List Configuration </Typography>
                      <Typography variant="body2" my={2}>
                        <p>The user can configure a job that imports files from the sftp server and creates DNC lists in Genesys Cloud. Only csv files are supported by GC, at this moment. The upload of csv file, selection of columns as well as SFTP server setup, is similar to the one described in Import Contact List Configuration.</p>

                        <p>Only “Intnal-Custom” DNC List Type is supported. The user can specify “Custom Exclusion Column”, “DNC upload Columns” and “DNC Expiration Column”. For more details on DNC Lists, please refer to:
                          <a href="https://help.mypurecloud.com/articles/create-new-dnc-list/"> https://help.mypurecloud.com/articles/create-new-dnc-list/</a>
                        </p>
                        <img src="../assets/help/importMatchCondition.png" />
                      </Typography>


                      <Typography variant="h6"> Export DNC List Configuration </Typography>
                      <Typography variant="body2" my={2}>
                        <p>This job configuration allows for exporting of DNC lists into files that are transferred to the SFTP server. The configuration is like the one described in Export Contact List Configuration. </p>

                        <img src="../assets/help/exportDNC.png" />
                      </Typography>
                    </div>
                  </Box>
                }



              </AccordionDetails>
            </Accordion>
          </Box>}


          {value == 'settings' && <Box my={2}>

            {/* NOTIFICATION SETTINGS */}
            <Typography variant="h6"> Notification Settings </Typography>
            <Typography variant="body2">
              <p> User can add emails addresses on which they wish to receive job failure notifications.</p>
            </Typography>
            <img src="../assets/help/addEmail.png" width={"500px"} />


            {/*ENCRYPTION SETTINGS */}
            <Typography variant="h6"> Encryption Settings </Typography>
            <Typography variant="body2">
              <p> This view has the following components-</p>
              <ul>
                <p><b>Secure all Contact Lists -</b>  Enables encryption for all Contact Lists. When enabled, you cannot enable/disable encryption for one specific Contact list. An export job will encrypt exported files using all public keys files uploaded by the user. </p>
                <p><b>Public Keys - </b> A list of User Public Keys that include:</p>
                <p><b>Type - </b> The type of key.</p>
                <p><b>User ID - </b> the user ID associated with the specific Public Key.</p>
                <p><b>Upload Date - </b> The date and time at which the Public Key was uploaded into CX Contact.</p>
                <p><b>Algorithm -</b>  Displays the public key algorithm provided by the List Builder</p>
                <p><b>Expiration Date -</b>  Displays the key expiration date for the public key or Never if the key never expires.</p>
                <p><b>Import -</b>  Enables you to import a user's public keys. When this option is selected an Import Public Key dialog is opened. In the Enter Public Key UserID field, enter the unique User ID, select the file that contains the Public Key that the app should use for data encryption and click Import. The app supports rsa3072 and rsa4096 Public Keys in plain text (ASCII Armor) format.</p>
                <p><b>Download CX Contact Public Key -</b>  Enables you to download a “Outbound list Manager for SFTP” Public key that should be used to encrypt data files before uploading them to the app. </p>
              </ul>
              <img src="../assets/help/encryption.png" width={"500px"} />
            </Typography>


          </Box>
          }


          {value == 'serverManagement' && <Box my={2}>
            <Typography variant="h5" my={2}> Managing Servers </Typography>
            <Typography variant="body2">
              <p>The <strong>Server Management Page</strong> provides a comprehensive interface for managing your SFTP servers. This page enables you to <strong>add new servers</strong> or <strong>remove existing servers</strong> with ease.</p>

              <div>
                <Typography variant="h6">  Adding New Servers </Typography>
                <p>To add a new server, follow these steps:</p>
                <ol>
                  <li><strong>Enter Server Details</strong>: Input either the <strong>domain name</strong> or the <strong>IP address</strong> of the server you wish to add.</li>
                  <li><strong>Add Server</strong>: Click on the <strong>"Add"</strong> button. Upon doing so, the server will be successfully added to the server list.</li>
                </ol>
              </div>

              <div>
                <Typography variant="h6">   Removing Existing Servers </Typography>
                <p>To remove an existing server, follow these steps:</p>
                <ol >
                  <li><strong>Locate the Server</strong>: Find the server you wish to remove in the server list.</li>
                  <li><strong>Delete Server</strong>: Click on the <strong>delete icon</strong> located on the right side under the <strong>Action</strong> tab. This action will remove the server from the list.</li>
                </ol>
              </div>
            </Typography>
            <img src="../assets/help/serverMangement.png" width={"600px"} />

          </Box>

          }

        </div>
      </div>
    </>
  );
};

export default ProtectedHelp;
