import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Alert,
  AlertColor,
  Backdrop,
  CircularProgress,
  Paper,
  Snackbar,
  Switch,
  TextField
} from "@mui/material";
import { useState, useEffect, ReactNode, ChangeEvent, useRef } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  deletePGPKey,
  getPGPPublicKey,
  getSecureContactSetting,
  getSettingsConfiguration,
  getUserMe,
  saveSettingsConfiguration,
  updateNotificationEmails,
  updateSecureContactSetting,
} from "../../services/purecloud";
import {
  ApiResponse,
  IKeyDetails,
  ISettingsDetails,
  IUser,
} from "../../models";
import moment from "moment";
import { getStorageItem } from "../../services/applicationStorage";

const Settings = () => {
  const [getContactListEncryption, setContactListEncryption] =
    useState<boolean>(false);
  const [getUserDetails, setUserDetails] = useState<IUser>({} as IUser);
  const [rows, setRows] = useState<Array<IKeyDetails>>(
    [] as Array<IKeyDetails>
  );
  const [emailRows, setEmailRows] = useState<Array<string>>([] as Array<string>);
  const [getPublicKeyDetails, setPublicKeyDetails] = useState<string>("");
  const [getPublicPGPKey, setPublicPGPKey] = useState<string>("");
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>();
  const [isFormDataLoading, setFormDataLoading] = useState<boolean>(false);
  const [isFileUploaded, setFileUploaded] = useState<boolean>(false);
  const [isEmailValid, setEmailValid] = useState<boolean>(false);
  const [getSnackDetails, setSnackDetails] = useState({
    open: false,
    message: (<></>) as ReactNode,
    severity: "success",
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [notificationEmailAddresses, setNotificationEmailAddresses] = useState<
  Array<string>
  >([]);
  const [getNewEmailAddress, setNewEmailAddress] = useState("");
  const [emailError, setEmailError] = useState(true);
  //This hooks is used to load initial data
  useEffect(() => {
    (async () => {
      try {
        setFormDataLoading(true);
        const apiResponse: ApiResponse<ISettingsDetails> =
          await getSettingsConfiguration();
        const response = await getSecureContactSetting();
        if (response.data) {
          setContactListEncryption(response.data.isSecureContactList);
          setNotificationEmailAddresses(response.data?.notificationEmailAddresses)
          setEmailRows(response.data?.notificationEmailAddresses)
        }
        setRows(apiResponse?.data?.sftpSetting || []);
        setFormDataLoading(false);
        const userDetails = await getUserMe();
        const publicKey = await getPGPPublicKey();
        setPublicKeyDetails(publicKey.data);
        setUserDetails(userDetails || {});
      } catch (err) {
        console.error(err);
        setFormDataLoading(false);
        setSnackDetails({
          open: true,
          message: <>Please try again</>,
          severity: "error",
        });
      }
    })();
  }, []);

  // This method is used to download the public key for pgp encryption
  const handlePublicKeyDownload = () => {
    // Creating a blob with MIME type 'text/plain'
    const file = new Blob([getPublicKeyDetails], {
      type: "text/plain",
    });

    // Creating a temporary link to trigger the download
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "publicKey.pub"; // Setting the file name for the download

    // Triggering the download
    element.click();

    // Optional: releasing the blob URL object after the download to free up resources
    URL.revokeObjectURL(element.href);
  };

  /**
   * This method is used handle the file upload event
   * @param e file
   * @returns void
   */
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      // Check if the file type is .pem, .txt,.asc,.pgp
      if (!file.name.match(/\.(pem|txt|asc|pgp)$/)) {
        alert("Please upload a valid file");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      // Check if the file size is greater than 20KB
      if (file.size > 20 * 1024) {
        alert("File size shouldn't be greater than 20KB");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target?.result) {
          const fileContents: string = event.target.result as string;
          setFileUploaded(true);
          setPublicPGPKey(fileContents);
        }
      };

      reader.readAsText(file);
    }
  };

   /**
   *This method is used to handle email validation
   */
   const handleEmailAddressChange = e => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      setEmailError(false)
      setNewEmailAddress(e.target.value)      
    } else {
      setEmailError(true)
    }
  };

  /**
   * This method is called when the form is submitted
   * @param data form data
   */
  const handleConfigurationFormSubmit = async (data) => {
    const configuration = {
      key: getPublicPGPKey,
      uploadDate: moment(),
      userId: getUserDetails.id,
      user: getUserDetails.name,
    };
    try {
      setFormDataLoading(true);
      await saveSettingsConfiguration(configuration);
      setSnackDetails({
        open: true,
        message: <>Key Uploaded Successfully</>,
        severity: "success",
      });
      setFileUploaded(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      const apiResponse: ApiResponse<ISettingsDetails> =
        await getSettingsConfiguration();
      setContactListEncryption(apiResponse?.data?.active || false);
      setRows(apiResponse?.data?.sftpSetting);
      setFormDataLoading(false);
    } catch (err) {
      console.log("setting form :", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };
  const handleNotificationFormSubmit = async (data) => {   
    if (!getNewEmailAddress) {
      return;
    }
    const configuration = {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      orgName: getStorageItem("organization-name", true, sessionStorage),
      notificationEmailAddresses: [...emailRows, getNewEmailAddress],
    };
    try {
      setFormDataLoading(true);
      await updateNotificationEmails(configuration);
      setSnackDetails({
        open: true,
        message: <>Email Added Successfully</>,
        severity: "success",
      });
      const apiResponse: ApiResponse<ISettingsDetails> =
        await getSecureContactSetting();
      setEmailRows(apiResponse?.data?.notificationEmailAddresses);
      setFormDataLoading(false);
    } catch (err) {
      console.log("setting form :", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };
  /**
   * This method is called when the secure contact list radio button is enabled/disabled
   * @param value true/false
   * @returns nothing
   */
  const updateSecureContactListConfiguration = async (value) => {
    try {
      setFormDataLoading(true);
      await updateSecureContactSetting({
        orgName: getStorageItem("organization-name", true, sessionStorage),
        orgId: getStorageItem("organization-id", true, sessionStorage),
        isSecureContactList: value,
      });
      setSnackDetails({
        open: true,
        message: <>Updated Successfully</>,
        severity: "success",
      });
      setContactListEncryption(value);
      setFormDataLoading(false);
    } catch (err) {
      console.log("updateSecureContactListConfiguration form :", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };
/**
   * This method is called when the secure contact list radio button is enabled/disabled
   * @param value true/false
   * @returns nothing
   */
  const updateNotificationEmailConfiguration = async (configuration) => {
    try {
      setFormDataLoading(true);
      await updateNotificationEmails(configuration);
      setSnackDetails({
        open: true,
        message: <>Updated Successfully</>,
        severity: "success",
      });
      setNotificationEmailAddresses(configuration.notificationEmailAddresses);
      setFormDataLoading(false);
    } catch (err) {
      console.log("updateNotificationEmailConfiguration form :", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleDeleteClick = (selectedRow) => async () => {
    try {
      setFormDataLoading(true);

      const filteredKeys = rows.filter(
        (row) => JSON.stringify(row) !== JSON.stringify(selectedRow)
      );
      await deletePGPKey(filteredKeys);
      setRows(filteredKeys);
      setFormDataLoading(false);

      setSnackDetails({
        open: true,
        message: <>Key Deleted Successfully</>,
        severity: "success",
      });
    } catch (err) {
      console.log("handleDeleteClick form :", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function CustomToolbar(props) {
    const handleDelete = () => {
      const selectedIDs = new Set(rowSelectionModel);
      // you can call an API to delete the selected IDs
      // and get the latest results after the deletion
      // then call setRows() to update the data locally here
      setRows((r) => r.filter((x) => !selectedIDs.has(x.id)));
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </GridToolbarContainer>
    );
  }

  const defaultWidthColumns = 50;
  const columns = [
    {
      field: "user",
      headerName: "User",
      minWidth: defaultWidthColumns,
      flex: 1,
    },
    {
      field: "algorithm",
      headerName: "Algorithm",
      minWidth: defaultWidthColumns,
      flex: 1,
    },
    {
      field: "expDate",
      headerName: "Expiration Date",
      minWidth: defaultWidthColumns,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.expDate === "Never"
          ? params.row.expDate
          : moment(params.row.expDate).format("MM/DD/YYYY, h:mm:ss a"),
    },
    {
      field: "uploadDate",
      headerName: "Upload Date",
      minWidth: defaultWidthColumns,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.uploadDate).format("MM/DD/YYYY, h:mm:ss a"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  /**
   * This method is used to delete server individual
   * @param serverName name of the server to be deleted
   * @returns void
   */
  const handleEmailDeleteClick = (email) => async () => {
    const updatedEmailList = emailRows.filter((v) => v !== email);
    const configuration = {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      orgName: getStorageItem("organization-name", true, sessionStorage),
      notificationEmailAddresses: updatedEmailList,
    };
    try {
      setFormDataLoading(true);
      await updateNotificationEmailConfiguration(configuration);
      setSnackDetails({
        open: true,
        message: <>Server Details Updated Successfully</>,
        severity: "success",
      });

      setEmailRows(configuration.notificationEmailAddresses);
      setFormDataLoading(false);
    } catch (err) {
      console.log("handleEmailDeleteClick failed :", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };
  const defaultWidthEmailColumns = 50;
  const emailColumns = [
    {
      field: "email",
      headerName: "Notification Email",
      minWidth: defaultWidthColumns,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      getActions: ({ row: { email } }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            title="Delete"
            onClick={handleEmailDeleteClick(email)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }} className="jobs-view">
      <div className="m-2 text">Notification Settings</div>
      <Paper
        elevation={2}
        sx={{
          margin: "10px 5px 5px 5px",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <span className="px-1 d-flex align-items-center text">
                Add Email
              </span>
          </div>
          <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 mt-2 mb-2">
            <TextField
              required
              label="Email Address"
              onChange={handleEmailAddressChange}                    
              helperText={emailError ? "{Please enter a valid email address}" : ""}
              inputProps={{
                type: "email",
              }}
              size="small"
              sx={{ width: "100%", padding: "0px 5px" }}
            />
          </div>
          <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mt-2 mb-2 d-flex justify-content-end last-row-button">
            <Button
              variant="contained"
              disabled={emailError}
              size="medium"
              sx={{
                color: "white",
                backgroundColor: "#1b2c48",
                marginRight: "5px",
              }}
              type="submit"
              onClick={() => {
                handleNotificationFormSubmit({});
              }}
            >
              Add
            </Button>
          </div>
        </div>
        <div className="row">
          <DataGrid
            rows={emailRows.map((email, index) => ({
              id: index + 1,
              email: email,
            }))}
            columns={emailColumns}
            initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      </Paper>
      <div className="m-2 text">Encryption Settings</div>
      <div className="m-2 text">
        Secure all contact list imports
        <Switch
          size="small"
          checked={getContactListEncryption}
          onChange={(_event, value: boolean) => {
            updateSecureContactListConfiguration(value);
          }}
        />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFormDataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper
        elevation={2}
        sx={{
          margin: "10px 5px 5px 5px",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <div className="row">
          <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <span className="text">Select File (.pem,.txt,.asc,.pgp)</span>
            <input
              type="file"
              ref={fileInputRef}
              className="form-control form-control-sm mt-1"
              onChange={handleFileUpload}
              accept=".pem, .txt, .asc, .pgp, .pub"
            />
          </div>
          <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex align-items-end">
            <Button
              variant="contained"
              size="medium"
              sx={{
                color: "white",
                backgroundColor: "#1b2c48",
                marginRight: "5px",
              }}
              disabled={!isFileUploaded}
              type="submit"
              onClick={() => {
                handleConfigurationFormSubmit({});
              }}
            >
              Upload
            </Button>
          </div>
        </div>
      </Paper>

      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFormDataLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          getRowId={(row) => JSON.stringify(row)}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
        />
        <div className="py-2 p-1 d-flex justify-content-end">
          <Button
            variant="contained"
            size="medium"
            sx={{ color: "white", backgroundColor: "#1b2c48" }}
            onClick={handlePublicKeyDownload}
          >
            Download Key
          </Button>
        </div>
      </Box>
      {getSnackDetails.open && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={getSnackDetails.open}
          autoHideDuration={5000}
          onClose={(_event, reason?: string) => {
            if (reason === "clickaway") {
              return;
            }
            setSnackDetails({ open: false, message: <></>, severity: "error" });
          }}
        >
          <Alert
            severity={getSnackDetails.severity as AlertColor}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {getSnackDetails.message as ReactNode}
          </Alert>
        </Snackbar>
      )}
    </Paper>
    
  );
};

export default Settings;
