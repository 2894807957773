// private config
const env = process.env.REACT_APP_CUSTOM_ENV.trim()
const custom_var = process.env.REACT_APP_SOLUTION.trim();
console.log(`Custom Var: ${custom_var}`)


let euRegion = new URLSearchParams(location.search).get("euregion");
// If EU Region not available, Make it false
euRegion = euRegion == 'true' ? euRegion : 'false'
console.log(`The EU Region Settings: ${euRegion}`)

if (euRegion == 'true') {
    sessionStorage.setItem('euRegion', 'true')
}
const environment = env === "production" ? "" : env === 'test' ? " Test" : env === 'demo' ? ' Demo' : env === 'sandbox' ? " Sandbox" : " Dev"

const appName = "Outbound List Manager for SFTP" + environment;
const provisioningInfo = {
    roles: [
        {
            name: appName,
            description: 'Generated role that is used by the application backend for ',
            permissionPolicies: [
                { // permission to allow backend to retrieve all integration types in validate org
                    domain: 'integrations',
                    entityName: 'integration',
                    actionSet: ["view"],
                    allowConditions: false
                },
                {
                    "domain": "authorization",
                    "entityName": "role",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "analytics",
                    "entityName": "userDetail",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "outbound",
                    "entityName": "contact",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "outbound",
                    "entityName": "contactList",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "outbound",
                    "entityName": "dncList",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "outbound",
                    "entityName": "dnc",
                    "actionSet": ["*"],
                    "allowConditions": false
                }
            ],
            type: "oauth"
        }
    ],
    oauth: {
        name: appName + ' OAuth',
        description: `Generated OAuth Client that is used by the application backend`,
        authorizedGrantType: 'CLIENT_CREDENTIALS',
        id: "01840872-f099-4596-a276-5297cf596fe2"
    }
}

const development = {
    purecloud: {
        clientId: '8a4ef85f-cfb9-4001-820b-b4ed74a3637d',// psinnovations org
        applicationName: appName,
        baseUrl: 'http://localhost:3000/'
    },
    pureengage: {

    },
    integrationType: "embedded-client-app",
    permissionPolicyEntityName: "examplePremiumApp",
    defaultPcEnv: 'mypurecloud.com',
    defaultLangTag: "en-us",
    provisioningInfo: provisioningInfo,
    product: 'purecloud',
    endpoints: {
        apiEndpoint: 'https://3qfpveqrbl.execute-api.us-east-1.amazonaws.com/dev',
        euApiEndpoint: 'https://f4hsgkzttk.execute-api.eu-central-1.amazonaws.com/dev'
    }
}

const test = {
    purecloud: {
        clientId: '8a4ef85f-cfb9-4001-820b-b4ed74a3637d', //pssinovations
        applicationName: appName,
        baseUrl: 'https://dqd5x0o7xsuqk.cloudfront.net'
    },
    integrationType: "embedded-client-app",
    permissionPolicyEntityName: "examplePremiumApp",
    defaultPcEnv: 'mypurecloud.com',
    defaultLangTag: "en-us",
    provisioningInfo: provisioningInfo,
    product: 'purecloud',
    endpoints: {
        apiEndpoint: 'https://u9nuiekheb.execute-api.us-east-1.amazonaws.com/test',
        euApiEndpoint: 'https://tccr2rclig.execute-api.eu-central-1.amazonaws.com/test'
    }
}

const production = {
    purecloud: {
        clientId: '8a4ef85f-cfb9-4001-820b-b4ed74a3637d',
        applicationName: appName
    },
    pureengage: {

    },
    integrationType: "premium-app-outbound-list-manager",
    permissionPolicyEntityName: "examplePremiumApp",
    defaultPcEnv: 'mypurecloud.com',
    defaultLangTag: "en-us",
    provisioningInfo: provisioningInfo,
    product: 'purecloud',
    endpoints: {},
    endpoints: {
        apiEndpoint: 'https://gfsh8zh6j1.execute-api.us-east-1.amazonaws.com/prod',
    }
}

const sandbox = {
    purecloud: {
        clientId: '',
        applicationName: appName
    },
    pureengage: {

    },
    product: 'purecloud',
    endpoints: {}
}


console.log('env:', env)
let config
switch (env) {
    case 'production': config = production; break;
    case 'development': config = development; break;
    case 'test': config = test; break;
    case 'sandbox': config = sandbox; break;
    default: break;
}

let euRegionStatus = sessionStorage.getItem('euRegion')

if (euRegion && euRegion == 'true')
    config.endpoints.apiEndpoint = config.endpoints.euApiEndpoint;

export const accessToken = () => {
    return JSON.parse(
        sessionStorage.getItem("purecloud-csp-token") ||
        sessionStorage.getItem(
            `purecloud-csp-token-${sessionStorage.getItem("orgId")}`
        )
    );
};

export const getEnv = () => {
    return JSON.parse(
        sessionStorage.getItem("purecloud-csp-env") ||
        sessionStorage.getItem(
            `purecloud-csp-env-${sessionStorage.getItem("orgId")}`
        )
    );
};

export const getStoredTenant = () => {
    return JSON.parse(
        sessionStorage.getItem("purecloud-csp-tenant")
    );
};

export default config
export const headerTitle = "Genesys Template"
