import { getStorageItem } from "./applicationStorage";
import config, { accessToken, getEnv } from "../config";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const fetchWrapper = async (url, init, token) => {
  const response = await fetch(url, {
    ...init,
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  });

  let correlationId;
  for (let pair of response.headers.entries()) {
    if (pair[0] === "inin-correlation-id") {
      correlationId = pair[1];
      break;
    }
  }

  const contentLength = response.headers.get("content-length");
  let responseBody = {};

  if (contentLength !== "0") {
    responseBody = await response.json();
  }
  return response.ok
    ? { ...responseBody, correlationId }
    : Promise.reject({ ...responseBody, correlationId });
};

export const isLoggedIn = () => {
  console.log("getting token", sessionStorage.getItem("purecloud-csp-token"));
  return sessionStorage.getItem("purecloud-csp-token") !== null;
};

export const getOrganization = async (environment, token) => {
  console.log(`getOrganization.env:${environment}, token:${token}`);
  const url = `https://api.${environment}/api/v2/organizations/me`;
  return await fetchWrapper(
    url,
    {
      method: "GET",
    },
    token
  );
};

const apiUrl = (endpoint) => `${config.endpoints.apiEndpoint}/${endpoint}`;

const makeRequest = async ({ method, endpoint, body }) => {
  try {
    const url = apiUrl(endpoint);
    console.log(`Request -- Method: ${method}, Endpoint: ${endpoint}`);

    const response = await fetch(url, {
      method,
      headers: {
        token: accessToken(),
        tokensource: "purecloud",
        env: getEnv(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const status = response.status;
      if (status === 403 || status === 401) {
        // Redirect to the login page if a 403 error occurs
        const env = getEnv();
        sessionStorage.clear();
        window.location.href = `/?environment=${env}`;
      } else {
        throw new Error(
          `Failed request: ${method} ${endpoint} - Status: ${status}`
        );
      }
    }

    return response.json();
  } catch (error) {
    console.error("Request Error:", error);
    throw error;
  }
};

/**
 * This method is used to save the sftp configuration
 * @param {Object} configuration configuration which needs to be saved
 * @returns Promise of Response success / failure
 */
export const saveSftpConfiguration = async (configuration) => {
  return makeRequest({
    method: "POST",
    endpoint: `saveSftpConfig`,
    body: configuration,
  });
};

/**
 * This method is used to save the sftp configuration
 * @param {Object} configuration configuration which needs to be saved
 * @returns Promise of Response success / failure
 */
export const getExitingSftpConfiguration = async (jobId) => {
  return makeRequest({
    method: "GET",
    endpoint: `getSpecificJobDetails/${getStorageItem(
      "organization-id",
      true,
      sessionStorage
    )}/${jobId}`,
  });
};

/**
 * This method is used to save the sftp configuration
 * @param {Object} configuration configuration which needs to be saved
 * @returns Promise of Response success / failure
 */
export const updateSftpConfiguration = async (configuration) => {
  return makeRequest({
    method: "PATCH",
    endpoint: "updateSftpConfig",
    body: configuration,
  });
};

/**
 * This method is used to get ssh public key
 * @param {string} serverName name of the server
 * @returns Promise of Response success / failure
 */
export const getSSHPublicKey = async () => {
  return makeRequest({
    method: "GET",
    endpoint: "retrieveSSHKey",
  });
};

/**
 * This method is used to delete the PGP Key
 * @param {Object} pgpKeys updated pgp keys
 * @returns Promise of Response success / failure
 */
export const deletePGPKey = async (pgpKeys) => {
  return makeRequest({
    method: "DELETE",
    endpoint: `deletePGPKey/${getStorageItem(
      "organization-id",
      true,
      sessionStorage
    )}`,
    body: pgpKeys
  });
};

/**
 * This method is used to get pgp public key
 * @param {string} serverName name of the server
 * @returns Promise of Response success / failure
 */
export const getPGPPublicKey = async () => {
  return makeRequest({
    method: "GET",
    endpoint: "retrievePGPKey",
  });
};

/**
 * This method is used to test the connection
 * @param {object} body sftp connection object
 * @returns Promise of Response success / failure
 */
export const testConnection = async (body) => {
  return makeRequest({
    method: "POST",
    endpoint: "connectSftp",
    body: body,
  });
};

/**
 * This method is used to enable event bridge rule the job
 * @param {object} body orgId and jobId object
 * @returns Promise of Response success / failure
 */
export const enableEventRuleJobService = async (body) => {
  return makeRequest({
    method: "POST",
    endpoint: "enableEventRule",
    body: body,
  });
};

/**
 * This method is used to Starts the contact List job
 * @param {object} body orgId and jobId object
 * @returns Promise of Response success / failure
 */
export const runContactListJobService = async (body) => {
  return makeRequest({
    method: "POST",
    endpoint: "runContactListJob",
    body: body,
  });
};

/**
 * This method is used to enable event bridge rule the job
 * @param {object} body orgId and jobId object
 * @returns Promise of Response success / failure
 */
export const disableEventRuleForJobService = async (body) => {
  return makeRequest({
    method: "POST",
    endpoint: "disableEventRule",
    body: body,
  });
};

/**
 * This method is used to fetch the job list
 * @returns returns Promise of Response which contains joblist
 */
export const getJobList = async () => {
  return makeRequest({
    method: "GET",
    endpoint: `getJobList/${getStorageItem(
      "organization-id",
      true,
      sessionStorage
    )}`,
  });
};

/**
 * This method is used to fetch the settings configuration
 * @returns returns Promise of Response which contains settings configuration
 */
export const getSettingsConfiguration = async () => {
  return makeRequest({
    method: "GET",
    endpoint: `getKeys/${getStorageItem(
      "organization-id",
      true,
      sessionStorage
    )}`,
  });
};

/**
 * This method is used to fetch the secure contactlist configuration
 * @returns returns Promise of Response which contains secure contactlist configuration
 */
export const getSecureContactSetting = async () => {
  return makeRequest({
    method: "GET",
    endpoint: `getOrgDetails/${getStorageItem(
      "organization-id",
      true,
      sessionStorage
    )}`,
  });
};

/**
 * This method is used to fetch the secure contactlist configuration
 * @returns returns Promise of Response which contains secure contactlist configuration
 */
export const getJobLogs = async () => {
  return makeRequest({
    method: "GET",
    endpoint: `joblogs/${getStorageItem(
      "organization-id",
      true,
      sessionStorage
    )}`,
  });
};

/**
 * This method is used to fetch the secure contactlist configuration
 * @param body true / false
 * @returns returns Promise of Response which contains secure contactlist configuration
 */
export const updateSecureContactSetting = async (body) => {
  return makeRequest({
    method: "POST",
    endpoint: `saveOrgConfig`,
    body: body,
  });
};
/**
 * This method is used to update the email addresses for notification
 * @param body true / false
 * @returns returns Promise of Response 
 */
export const updateNotificationEmails = async (body) => {
  return makeRequest({
    method: "POST",
    endpoint: `saveOrgConfig`,
    body: body,
  });
};
/**
 * This method is used to fetch the settings configuration
 * @returns returns Promise of Response which contains settings configuration
 */
export const saveSettingsConfiguration = async (settings) => {
  return makeRequest({
    method: "POST",
    endpoint: `savePgpSettings`,
    body: {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      active: true,
      env: getEnv(),
      orgName: getStorageItem("organization-name", true, sessionStorage),
      sftpSetting: [settings],
    },
  });
};

/**
 * This method is used to save the server configuration
 * @returns returns Promise of success
 */
export const saveServerDetails = async (body) => {
  return makeRequest({
    method: "PUT",
    endpoint: `saveServerList`,
    body: body,
  });
};

/**
 * This method is used to fetch the job list
 * @returns returns Promise of Response which contains joblist
 */
export const deleteJobs = async (deleteJobs) => {
  return makeRequest({
    method: "DELETE",
    endpoint: `deleteJobs`,
    body: { deleteJobs },
  });
};

/**
 * This method is used to fetch the existing sftp configuration
 * @returns returns Promise of Response which contains joblist
 */
export const getExitingConfiguration = async (jobId) => {
  return makeRequest({
    method: "GET",
    endpoint: `getJobList/${getStorageItem(
      "organization-id",
      true,
      sessionStorage
    )}/${jobId}`,
  });
};

/**
 * This method is used ot get the my user details
 * @returns My User Details response
 */
export const getUserMe = async (noOfRetries = 2) => {
  const baseUrl = `https://api.${getStorageItem(
    "purecloud-csp-env",
    true,
    sessionStorage
  )}`;
  const url = `${baseUrl}/api/v2/users/me`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getStorageItem(
          "purecloud-csp-token",
          true,
          sessionStorage
        )}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    if (response.status === 403 || response.status === 401) {
      // Redirect to the login page if a 403 error occurs
      const env = getEnv();
      sessionStorage.clear();
      window.location.href = `/?environment=${env}`;
      return;
    }

    if (response.status === 429) {
      const retrySeconds = response.headers.get("retry-after") || 2;
      console.log(
        `Waiting ${retrySeconds} seconds before reaching to Genesys Cloud`
      );
      await sleep(parseInt(retrySeconds) * 1000);
      return await getUserMe(noOfRetries - 1);
    } else {
      throw response;
    }
  } catch (err) {
    console.log("getUserMe error:", err);
    if (noOfRetries > 0) {
      return await getUserMe(noOfRetries - 1);
    } else {
      throw err;
    }
  }
};

/**
 * This method is used to fetch the allcontactlist
 * @returns returns Promise of allcontactlist
 */
export const getAllContactList = async () => {
  const response = [];
  let pageNumber = 1;
  const pageSize = 100;

  try {
    while (true) {
      const baseUrl = `https://api.${getStorageItem(
        "purecloud-csp-env",
        true,
        sessionStorage
      )}`;
      let url = `${baseUrl}/api/v2/outbound/contactlists?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=name&sortOrder=ascending`;
      const result = await getGCAPIWithRetries(url);
      const data = result?.entities || [];
      response.push(...data);
      pageNumber++;

      if (!result?.pageCount || pageNumber > result?.pageCount) {
        break;
      }
    }

    return response;
  } catch (err) {
    throw err.message;
  }
};

/**
 * This method is used to fetch the alldnclist
 * @returns returns Promise of alldnclist
 */
export const getAllDNCList = async () => {
  const response = [];
  let pageNumber = 1;
  const pageSize = 100;

  try {
    while (true) {
      const baseUrl = `https://api.${getStorageItem(
        "purecloud-csp-env",
        true,
        sessionStorage
      )}`;
      let url = `${baseUrl}/api/v2/outbound/dncLists?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=name&sortOrder=ascending`;
      const result = await getGCAPIWithRetries(url);
      const data = result?.entities || [];
      response.push(...data);
      pageNumber++;

      if (!result?.pageCount || pageNumber > result?.pageCount) {
        break;
      }
    }

    return response;
  } catch (err) {
    throw err.message;
  }
};

/**
 * This method is used to fetch the Campaign
 * @returns returns Promise of Campaign
 */
export const getAllCampaign = async () => {
  const response = [];
  let pageNumber = 1;
  const pageSize = 100;

  try {
    while (true) {
      const baseUrl = `https://api.${getStorageItem(
        "purecloud-csp-env",
        true,
        sessionStorage
      )}`;
      let url = `${baseUrl}/api/v2/outbound/campaigns?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=name&sortOrder=ascending`;
      const result = await getGCAPIWithRetries(url);
      const data = result?.entities || [];
      response.push(...data);
      pageNumber++;

      if (!result?.pageCount || pageNumber > result?.pageCount) {
        break;
      }
    }

    return response;
  } catch (err) {
    throw err.message;
  }
};

/**
 * This method is used ot get the File Spec list
 * @returns Existing File Spec List response
 */
export const getGCAPIWithRetries = async (url, noOfRetries = 2) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getStorageItem(
          "purecloud-csp-token",
          true,
          sessionStorage
        )}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    if (response.status === 403 || response.status === 401) {
      // Redirect to the login page if a 403 error occurs
      const env = getEnv();
      sessionStorage.clear();
      window.location.href = `/?environment=${env}`;
      return;
    }

    if (response.status === 429) {
      const retrySeconds = response.headers.get("retry-after") || 2;
      console.log(
        `Waiting ${retrySeconds} seconds before reaching to Genesys Cloud`
      );
      await sleep(parseInt(retrySeconds) * 1000);
      return await getGCAPIWithRetries(url, noOfRetries - 1);
    } else {
      throw response;
    }
  } catch (err) {
    console.error("getGCAPIWithRetries error:", err);
    if (noOfRetries > 0) {
      return await getGCAPIWithRetries(url, noOfRetries - 1);
    } else {
      throw err;
    }
  }
};

/**
 * This method is used to fetch the All File Specification Templates
 * @returns returns Promise of All File Specification Templates
 */
export const getAllFileSpecificationTemplates = async () => {
  const response = [];
  let pageNumber = 1;
  const pageSize = 100;

  try {
    while (true) {
      const baseUrl = `https://api.${getStorageItem(
        "purecloud-csp-env",
        true,
        sessionStorage
      )}`;
      let url = `${baseUrl}/api/v2/outbound/fileSpecificationTemplates?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      const result = await getGCAPIWithRetries(url);
      const data = result?.entities || [];
      response.push(...data);
      pageNumber++;

      if (!result?.pageCount || pageNumber > result?.pageCount) {
        break;
      }
    }

    return response;
  } catch (err) {
    throw err.message;
  }
};

/**
 * This method is used to fetch the All File Specification Templates
 * @returns returns Promise of All File Specification Templates
 */
export const getAllImportTemplates = async () => {
  const response = [];
  let pageNumber = 1;
  const pageSize = 100;

  try {
    while (true) {
      const baseUrl = `https://api.${getStorageItem(
        "purecloud-csp-env",
        true,
        sessionStorage
      )}`;
      let url = `${baseUrl}/api/v2/outbound/importTemplates?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      const result = await getGCAPIWithRetries(url);
      const data = result?.entities || [];
      response.push(...data);
      pageNumber++;

      if (!result?.pageCount || pageNumber > result?.pageCount) {
        break;
      }
    }

    return response;
  } catch (err) {
    throw err.message;
  }
};

/**
 * This method is used to fetch the allcontactlist
 * @returns returns Promise of allcontactlist
 */
export const getAllDomainList = async () => {
  const response = [];
  let pageNumber = 1;
  const pageSize = 25;
  const permission = "outbound:contactlist:add";
  try {
    while (true) {
      const baseUrl = `https://api.${getStorageItem(
        "purecloud-csp-env",
        true,
        sessionStorage
      )}/api/v2`;
      let url = `${baseUrl}/authorization/divisionspermitted/paged/me?pageSize=${pageSize}&pageNumber=${pageNumber}&permission=${permission}`;
      const result = await getGCAPIWithRetries(url);
      const data = result?.entities || [];
      response.push(...data);
      pageNumber++;

      if (!result?.pageCount || pageNumber > result?.pageCount) {
        break;
      }
    }

    return response;
  } catch (err) {
    throw err.message;
  }
};

/**
 * This method is used to fetch the get server list
 * @returns returns Promise of Response which contains server list
 */
export const getServerListService = async (jobId) => {
  return makeRequest({
    method: "GET",
    endpoint: `getServerList/${getStorageItem(
      "organization-id",
      true,
      sessionStorage
    )}`,
  });
};

export default {
  isLoggedIn,
  fetchWrapper,
  getOrganization,
  saveSftpConfiguration,
  getAllContactList,
  getServerListService,
  getAllDomainList,
  deleteJobs,
  runContactListJobService,
  getSettingsConfiguration,
  saveServerDetails,
  saveSettingsConfiguration,
  getSSHPublicKey,
  getPGPPublicKey,
  getSecureContactSetting,
  updateSecureContactSetting,
  getAllCampaign,
};
